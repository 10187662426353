/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Layout, Text, Seo, Footer } from '../../shared/ui';

const adapterContactInfo = (info) => {
  if (info.length === 1) {
    return [
      {
        email: info[0],
      },
    ];
  }

  // eslint-disable-next-line array-callback-return, consistent-return
  const changeArrayOfStringsToArrayOfObjects = info.map((el, i, arr) => {
    if (el.includes('@') && !arr[i + 1].includes('1')) {
      return {
        email: el,
        fullName: arr[i - 1],
        position: arr[i - 2],
      };
    }

    if (el.includes('@') && arr[i + 1].includes('1')) {
      return {
        email: el,
        // TO-DO: cleanup
        phone: arr[i + 1] === '+1 (000) 000-0000' ? '' : arr[i + 1],
        position: arr[i - 1],
      };
    }
  });

  const deleteUndefined = changeArrayOfStringsToArrayOfObjects.filter(
    (el) => el !== undefined
  );

  return deleteUndefined;
};

const adapterData = (contactsData) => {
  const result = contactsData.map((element) => ({
    id: element.id,
    title: element.title,
    data: adapterContactInfo(element.contactInformation),
  }));

  return result;
};

const Contact: React.FC = (props) => {
  // @ts-ignore
  const contactsData = props.data.allContentfulContact.nodes;
  const adaptedData = adapterData(contactsData);

  useEffect(() => {
    document.title = contactsData[0].title;
    // console.log(contactsData[0].title);
    console.log(adaptedData);
  }, []);

  return (
    <Layout transparentNav>
      <Seo title="Contact - Bolt Tv" />
      <div className=" mb-32 sm:mb-80 px-4 sm:px-0 sm:w-[90%] lg:w-[75%] mx-auto mt-32 font-jakarta">
        <div className=" flex flex-col-reverse sm:flex-row sm:gap-12">
          <div className="">
            <Text
              type="contact-light"
              title="For commercial or"
              className=" sm:mt-20"
            />
            <Text
              type="contact-light"
              title="branded sales email us at"
              className="mb-2"
            />
            <a
              href="mailto: sales@bolt.tv"
              className="slide-link-text mb-8 sm:mb-28"
            >
              <h2 className="hover-shade w-fit text-white text-[30px] lg:text-[40px] font-semibold">
                Commercial Sales
              </h2>
            </a>
            {adaptedData?.[0]?.data?.slice(0, 3)?.map((d, i) => (
              <div key={i}>
                <a href={`mailto: ${d?.email}`} className="slide-link-text">
                  <Text
                    type="contact-head"
                    title={d?.position}
                    className="hover-shade w-fit"
                  />
                </a>
                <Text
                  type="contact-light"
                  title={d?.fullName}
                  className="mb-8 sm:mb-12"
                />
              </div>
            ))}
            <div>
              <a href="mailto: david@bolt.tv" className="slide-link-text">
                <Text
                  type="contact-head"
                  title="David Ganz"
                  className="hover-shade w-fit"
                />
              </a>
              <Text
                type="contact-light"
                title="Head of Development"
                className="mb-8 sm:mb-12"
              />
            </div>
          </div>
          <div className=" sm:w-[50%] ml-auto">
            <Text
              type="contact-head"
              title="3003 South Loop West Suite 108 Houston, TX 77054"
              className=" mb-8 sm:mb-12"
            />
            <Text
              type="contact-head"
              title="+1 281 407 4807"
              className=" mb-8 sm:mb-12"
            />
            <Text type="contact-light" title="For general inquires or" />
            <Text type="contact-light" title="director roster email us at" />
            <a
              href="mailto: hello@bolt.tv"
              className="slide-link-text mb-8 sm:mb-12"
            >
              <Text
                type="contact-head"
                title="General Inquires"
                className="hover-shade w-fit"
              />
            </a>
            <Text
              type="contact-light"
              title="For job opportunities email us at"
            />
            <a href="mailto: jobs@bolt.tv" className="slide-link-text mb-12">
              <Text
                type="contact-head"
                title="Jobs"
                className="hover-shade w-fit"
              />
            </a>
            <p className=" text-white mt-[130px] lg:mt-[150px] xl:mt-[185px] font-bold leading-normal text-base hidden sm:block">
              Submission Notice: Bolt Tv and all of its subsideraries do not
              accept unsolicited material, including scripts, treatments,
              briefs, or any other documents. Thank you in advance for using the
              proper channels. It's greatly appreciated!
            </p>
          </div>
        </div>
        <p className=" text-white font-bold text-[12px] block sm:hidden mt-12 mb-12">
          Submission Notice: Bolt Tv and all of its subsideraries do not accept
          unsolicited material, including scripts, treatments, briefs, or any
          other documents. Thank you in advance for using the proper channels.
          It's greatly appreciated!
        </p>

        <div className="bg-gradient-to-r from-transparent via-white to-transparent w-[55%] mx-auto h-[1px] mt-12 mb-12 sm:mt-20 sm:mb-20" />

        <h2 className=" text-white text-[24px] sm:text-[30px] lg:text-[40px] font-semibold mb-8 sm:mb-20">
          Regional Sales
        </h2>

        {adaptedData?.[1]?.data?.map((d, i) => (
          <div key={i}>
            <a href={`mailto: ${d?.email}`} className="slide-link-text">
              <Text
                type="contact-head"
                title={d?.position}
                className="hover-shade w-fit"
              />
            </a>
            <div>
              <a
                href={`mailto: ${d?.email}`}
                className="slide-link-text mb-8 sm:mb-12"
              >
                <Text type="contact-light" title={d?.email} />
              </a>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query Contact {
    allContentfulContact(sort: { fields: createdAt, order: ASC }) {
      nodes {
        id
        title
        contactInformation
      }
    }
  }
`;

export default Contact;
